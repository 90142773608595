import { awaitTo } from "./request";
import { ScaleType } from "../page/SuperPixel/constant";

// 获取 OSS config
export function apiGetOssConfig() {
  return awaitTo(`/v1/oss/sts`, {
    type: "GET",
  });
}

export const addTask = (type: ScaleType, url: string) => {
  return awaitTo("/v2/image/enlarge/add", {
    type: "POST",
    data: {
      type,
      url,
    },
  });
};

export const getProcess = (id: string) => {
  return awaitTo("/v2/image/enlarge/get", {
    type: "GET",
    params: { id },
  });
};

// 获取图片信息
export function getImageInfo(imgUrl: string) {
  return awaitTo(imgUrl, {
    type: "GET",
    params: { "x-oss-process": "image/info" },
  });
}

import { FC, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Upload,
  UploadProps,
  Image,
  Select,
  Progress,
  Spin,
  message,
} from "antd";
import { RcFile } from "antd/es/upload";
import classnames from "classnames";
import Iamge_logo from "../../assets/logo.png";
import Iamge_uploadEmpty from "../../assets/uploadEmpty.png";
import Iamge_Add from "../../assets/add.png";
import Iamge_Close from "../../assets/close.png";
import Iamge_Back from "../../assets/back.png";
import SlideDiff from "../../components/SlideDiff";
import { addTask, getProcess } from "../../api";
import useUpload from "../../hooks/useUpload";
import {
  arraysAreEqual,
  downloadImage,
  downloadImages,
  formatBytes,
  // needChange,
} from "../../utils";
import { FileItem, ScaleType, StatusType, options } from "./constant";
import "./index.scss";
import { SchemaToApp } from "../../utils/index"

const SuperPixel: FC<{}> = () => {
  // const [fileList, setFileList] = useState<UploadProps["fileList"]>([]);
  const [fileList] = useState<UploadProps["fileList"]>([]);
  const [list, setList] = useState<FileItem[]>([]);
  const [activeFile, setActiveFile] = useState<FileItem>();
  const [batchValue, setBatchValue] = useState<ScaleType>();
  const lastValues = useRef<(string | undefined)[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [batchDownloadLoading, setBatchDownloadLoading] = useState(false);

  const { onUpload } = useUpload();
  const isApp = window.judgeEnv.pageEnv == 'app'
  const customRequest: UploadProps["customRequest"] = async (options) => {
    console.log(options);

    const file = options.file as RcFile;

    const newListItem: FileItem = {
      name: file.name,
      id: file.uid,
      status: StatusType.init,
      progress: 0,
      imageInfo: {
        size: formatBytes(file.size),
      },
    };

    list.push(newListItem);
    setList([...list]);

    if (list.length === 1 && !activeFile) {
      // 上传的第一个默认选中
      setActiveFile(newListItem);
    }

    // 上传 oss
    const initUrl = await onUpload(file, file.name);
    // console.log("res", initUrl);

    // 先把状态变成oss完成
    newListItem.initUrl = initUrl;
    newListItem.status = StatusType.ossCompleted;
    setList([...list]);

    // 再慢慢获取图片信息
    // const res = await getImageInfo(initUrl);
    // console.log("res", res);

    // 1. 获取图片宽高
    const img = new window.Image();
    // 设置图片 src
    img.src = initUrl;
    img.onload = function () {
      newListItem.imageInfo.width = img.width;
      newListItem.imageInfo.height = img.height;
      setList((l) => [...l]);
    };

    // 2. 获取图片字节大小
    // fetch(initUrl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // console.log("Size:", blob.size, "bytes");
    //     newListItem.imageInfo.size = formatBytes(blob.size);
    //     setList((l) => [...l]);
    //   });

    return true;
  };

  const onChangeItem = (i: FileItem, e: ScaleType) => {
    i.optionValue = e;
    setList((l) => [...l]);
  };

  const onChangeBatch = (e: ScaleType) => {
    setBatchValue(e);
    const targetList = list.filter((i) =>
      [
        StatusType.init,
        StatusType.ossCompleted,
        StatusType.waitingUpload,
      ].includes(i.status)
    );
    if (targetList.length) {
      targetList.forEach((i) => {
        i.optionValue = e;
      });
      setList((l) => [...l]);
    }
  };

  // 联动逻辑
  useEffect(() => {
    const values = Array.from(new Set(list.map((i) => i.optionValue)));

    if (arraysAreEqual(values, lastValues.current)) return;

    // console.log("values", values);

    if (values.length === 1) {
      setBatchValue(values[0]);
    } else {
      setBatchValue(undefined);
    }
    lastValues.current = values;
  }, [list]);

  const onClickUploadItem = (i: FileItem) => {
    i.status = StatusType.waitingUpload;
    setList((l) => [...l]);
    startUpload();
  };

  const handleBatchUpload = () => {
    const hasChoosed = list.some((j) => typeof j.optionValue !== "undefined");

    if (!hasChoosed) {
      message.error("请选择超分选项");
      return;
    }

    const to_wait_list = list.filter(
      (i) => i.status === StatusType.ossCompleted && i.optionValue
    );

    if (!to_wait_list.length) {
      message.error("当前没有需要超分的图片");
      return;
    }

    to_wait_list.forEach((i) => {
      i.status = StatusType.waitingUpload;
    });

    setList((l) => [...l]);

    startUpload();
  };

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  const startUpload = async () => {
    const uploadingItem = list.find((i) => i.status === StatusType.uploading);

    if (uploadingItem) return;

    const waiting_list = list.filter(
      (i) => i.status === StatusType.waitingUpload
    );

    if (!waiting_list.length) return;

    const waiting_Item = waiting_list[0];

    waiting_Item.status = StatusType.uploading;

    setList((l) => [...l]);

    const startTimer = async (id: string) => {
      const [err, res] = await getProcess(id);

      if (err !== null || !res || !res?.data) {
        waiting_Item.status = StatusType.error;
        setList((l) => [...l]);
        startUpload();

        return;
      }

      // console.log("getProcess, res", res.data);

      waiting_Item.progress = res.data?.remainingProgress;
      // console.log("waiting_Item.progress", waiting_Item.progress);
      setList((l) => [...l]);

      if (waiting_Item.progress >= 100) {
        waiting_Item.status = StatusType.finish;
        waiting_Item.afterUrl = (res.data?.data?.[0] || "").replace(
          /http:/g,
          "https:"
        );

        console.log("waiting_Item.afterUrl", waiting_Item.afterUrl);

        setList((l) => [...l]);
        startUpload();
      } else {
        timer.current = setTimeout(() => startTimer(id), 1000);
      }
    };

    // api
    const [err, res] = await addTask(
      waiting_Item.optionValue || options[0].value,
      waiting_Item.initUrl!
    );
    if (err !== null) return;
    const id = res.data;
    startTimer(id);
  };

  const handleDelete = (i: FileItem) => {
    setList((l) => l.filter((j) => j.id !== i.id));
    if (activeFile?.id === i.id) {
      const newList = list.filter((j) => j.id !== i.id);
      if (newList.length) {
        setActiveFile(newList[0]);
      } else {
        setActiveFile(undefined);
      }
    }

    if (i.status === StatusType.uploading) {
      timer.current && clearTimeout(timer.current);
      startUpload();
    }
  };

  const handleBatchDownLoad = async () => {
    const finishList = list.filter((i) => i.status === StatusType.finish);

    if (!finishList.length) {
      message.error("当前没有超分完成的图片");
      return;
    }

    const targetList: string[] = finishList.map((j) => j.afterUrl!);
    setBatchDownloadLoading(true);
    if (isApp) {
      SchemaToApp(`newbrushapp://newbrush?type=fun&method=downLoadImg&imgList=${JSON.stringify(targetList)}`, () => { })
    } else {
      await downloadImages(targetList);
    }

    setBatchDownloadLoading(false);
  };
  const downloadImageHandle = (url: string) => {
    let imgList = [url]
    if (isApp) {
      SchemaToApp(`newbrushapp://newbrush?type=fun&method=downLoadImg&imgList=${JSON.stringify(imgList)}`, () => { })
    } else {
      downloadImage(url)
    }
  }
  const backHandler = () => {
    SchemaToApp(`newbrushapp://newbrush?type=fun&method=backToApp`, () => { })
  }
  return (
    <div style={{ position: "relative" }}>

      {
        isApp ? <div className="pageTitle">
          <img onClick={backHandler} src={Iamge_Back} alt="" />
          <span>妙笔Ai超分</span>
        </div> : null
      }
      <header className="header">
        <img src={Iamge_logo} alt="" />
      </header>

      <Row className="section">
        <Col span={12} style={{ height: "100%" }}>
          <div className="left">
            {list?.length === 0 && (
              <>
                <img src={Iamge_uploadEmpty} alt="" />
                <p>快来上传图片吧</p>
              </>
            )}

            {activeFile && activeFile.status !== StatusType.finish && (
              <Image
                className="preview"
                src={activeFile.initUrl}
                preview={false}
              />
            )}

            {activeFile && activeFile.status === StatusType.finish && (
              <SlideDiff
                activeFile={activeFile}
                // left={activeFile.initUrl!}
                // right={activeFile.afterUrl!}
                handleFullScreen={() => setIsModalOpen(true)}
              />
            )}
          </div>
        </Col>

        <Col className="right" span={12}>
          <Row>
            <Upload
              accept={"image/png, image/jpeg, image/jpg, image/webp"}
              fileList={fileList}
              className="Upload"
              showUploadList={false}
              customRequest={customRequest}
              multiple
            >
              <Button className="Add" type="primary">
                <img src={Iamge_Add} alt="" />
              </Button>
            </Upload>
          </Row>

          <div className="List">
            {list.map((i, index) => {
              // const imageNoNeedChange = needChange(i);
              return (
                <Spin key={i.id} spinning={i.status === StatusType.init}>
                  <Row
                    className={classnames("item", {
                      grey: index % 2 !== 0,
                      active: activeFile?.id === i.id,
                    })}
                  >
                    <Col
                      span={6}
                      className="iconBg"
                      onClick={() => setActiveFile(i)}
                    >
                      <img className="icon" src={i.initUrl} alt="" />
                    </Col>

                    <Col span={18} style={{ padding: "0 22px 0 18px" }}>
                      <Progress
                        percent={i.progress}
                        trailColor="#fff"
                        status={
                          i.status === StatusType.uploading
                            ? "active"
                            : "normal"
                        }
                      />
                      <Row style={{ marginTop: 9 }}>
                        <Col span={12} style={{ paddingRight: 5 }}>
                          <p className="title">原图信息</p>
                          <p className="contentText">
                            {i.imageInfo.width ? (
                              <span>
                                {i.imageInfo.width} x {i.imageInfo.height} px
                                &nbsp;
                              </span>
                            ) : (
                              <> &nbsp;</>
                            )}
                            <span>{i.imageInfo.size}</span>
                          </p>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 6 }}>
                          <p className="title">超分选项</p>
                          <Select
                            style={{ width: "100%", marginTop: 20 }}
                            options={options}
                            disabled={i.status === StatusType.finish}
                            value={i.optionValue}
                            onChange={(e) => onChangeItem(i, e)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 9 }}>
                        <Col span={12}>
                          {/* {imageNoNeedChange && (
                            <p className="ErrorText">原图过大</p>
                          )} */}
                        </Col>
                        <Col span={12} className="flex-right">
                          <Button
                            type="primary"
                            disabled={
                              i.status !== StatusType.ossCompleted ||
                              !i.optionValue
                              // imageNoNeedChange
                            }
                            onClick={() => onClickUploadItem(i)}
                          >
                            超分
                          </Button>
                          {/* {i.status === StatusType.ossCompleted && ()} */}
                          {i.status !== StatusType.finish && (
                            <Button
                              type="primary"
                              onClick={() => handleDelete(i)}
                            >
                              删除
                            </Button>
                          )}
                          {i.status === StatusType.finish && (
                            <Button
                              type="primary"
                              onClick={() => i.afterUrl && downloadImageHandle(i.afterUrl)}
                            >
                              下载
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Spin>
              );
            })}
          </div>

          <footer className="Tools">
            <p className="title" style={{ paddingLeft: 24, marginBottom: 18 }}>
              批量超分选项
            </p>
            <Row>
              <Col span={12} style={{ paddingLeft: 24 }}>
                <Select
                  // defaultValue="lucy"
                  style={{ width: "100%" }}
                  options={options}
                  value={batchValue}
                  onChange={onChangeBatch}
                />
              </Col>

              <Col
                span={12}
                className="flex-right"
                style={{ paddingRight: 45 }}
              >
                <Button
                  type="primary"
                  onClick={handleBatchUpload}
                  disabled={
                    !list.some((j) => typeof j.optionValue !== "undefined")
                  }
                >
                  批量超分
                </Button>
                <Button
                  type="primary"
                  onClick={handleBatchDownLoad}
                  loading={batchDownloadLoading}
                  disabled={
                    !list.filter((i) => i.status === StatusType.finish).length
                  }
                >
                  批量下载
                </Button>
              </Col>
            </Row>
          </footer>
        </Col>
      </Row>

      {isModalOpen && activeFile && (
        <div className="modal">
          <img
            src={Iamge_Close}
            className="closeIcon"
            alt=""
            onClick={() => setIsModalOpen(false)}
          />
          <img src={activeFile.afterUrl!} className="modalImg" alt="" />
        </div>
      )}
    </div>
  );
}

export default SuperPixel;

export enum StatusType {
  "init",
  "ossCompleted",
  "waitingUpload",
  "uploading",
  "finish",
  "error",
}

export interface FileItem {
  id: string;
  initUrl?: string;
  afterUrl?: string;
  name: string;
  status: StatusType;
  progress: number;
  optionValue?: ScaleType;
  imageInfo: {
    width?: string | number;
    height?: string | number;
    size?: string;
  };
}

export enum ScaleType {
  IMAGE_CH_1 = "IMAGE_CH_1",
  IMAGE_CH_2 = "IMAGE_CH_2",
  IMAGE_CH_3 = "IMAGE_CH_3",
  IMAGE_CH_4 = "IMAGE_CH_4",
  IMAGE_CH_5 = "IMAGE_CH_5",
  IMAGE_CH_6 = "IMAGE_CH_6",
  IMAGE_CH_7 = "IMAGE_CH_7",
  IMAGE_IN_1 = "IMAGE_IN_1",
  IMAGE_IN_2 = "IMAGE_IN_2",
}

export const options = [
  {
    label: "(1:1) 200*200 mm (2362*2362px)",
    value: ScaleType.IMAGE_CH_1,
    width: 2362,
    height: 2362,
  },
  {
    label: "(1:1) 260*260 mm (3071*3071px)",
    value: ScaleType.IMAGE_CH_2,
    width: 3071,
    height: 3071,
  },
  {
    label: "(1:1) 140*140 mm (1654*1654px)",
    value: ScaleType.IMAGE_CH_3,
    width: 1654,
    height: 1654,
  },
  {
    label: "(1:357) 210*285 mm (2480*3366px)",
    value: ScaleType.IMAGE_CH_4,
    width: 2480,
    height: 3366,
  },
  {
    label: "(1:1.45) 140*203 mm (1654*2398px)",
    value: ScaleType.IMAGE_CH_5,
    width: 1654,
    height: 2398,
  },
  {
    label: "(1.357:1) 285*210 mm (3366*2480px)",
    value: ScaleType.IMAGE_CH_6,
    width: 3366,
    height: 2480,
  },
  {
    label: "(1:1.414) 210*297 mm (3366*2480px)",
    value: ScaleType.IMAGE_IN_1,
    width: 2480,
    height: 3508,
  },
  {
    label: "(1.414:1) 297*210 mm (3508*2480px)",
    value: ScaleType.IMAGE_IN_2,
    width: 3508,
    height: 2480,
  },
];

import { FC, useEffect, useRef, useState } from "react";
import { Button, Row } from "antd";
import classnames from "classnames";

import "./index.scss";
import { FileItem } from "../../page/SuperPixel/constant";

interface SlideDiffProps {
  activeFile: FileItem;
  // left: string;
  // right: string;
  handleFullScreen: () => void;
}
const SlideDiff: FC<SlideDiffProps> = ({ activeFile, handleFullScreen }) => {
  const [position, setPosition] = useState(0);
  const [baseImage, setBaseImage] = useState({ width: 0, height: 0 });
  const draggableRef = useRef<any>(null);
  const refBaseImg = useRef<HTMLImageElement>(null);
  const offsetXRef = useRef(0);
  const isDrag = useRef(false);
  // const offsetYRef = useRef(0);

  const onMouseDown: React.DragEventHandler<HTMLDivElement> = (e) => {
    // console.log("onMouseDown", e);

    const { offsetLeft } = draggableRef.current;
    offsetXRef.current = e.clientX - offsetLeft;
    // offsetYRef.current = e.clientY - offsetTop;
    isDrag.current = true;

    // 隐藏拖影
    // const ghostImg = new Image();
    // ghostImg.src = "";
    // e.dataTransfer.setDragImage(ghostImg, 0, 0);
  };

  const onMouseMove: React.DragEventHandler<HTMLDivElement> = (e) => {
    if (!isDrag.current) return;
    // console.log("onMouseMove", e);
    const x = e.clientX - offsetXRef.current;
    // const y = e.clientY - offsetYRef.current;
    setPosition(x);
  };

  const handleMouseUp = () => {
    // console.log("handleMouseUp");

    isDrag.current = false;
  };

  const [leftOpacity, setLeftOpacity] = useState(0);

  const onLoad = () => {
    setLeftOpacity(1);
    if (refBaseImg.current?.width) {
      setPosition(refBaseImg.current?.width / 2);
      setBaseImage({
        width: refBaseImg.current.width,
        height: refBaseImg.current.height,
      });
    }
  };

  useEffect(() => {
    const event = () => {
      onLoad();
    };
    document.addEventListener("resize", event);
    return () => {
      document.removeEventListener("resize", event);
    };
  }, []);

  const [imgs, setImgs] = useState({
    left: activeFile.initUrl,
    right: activeFile.afterUrl,
  });

  useEffect(() => {
    // 切换时先置空
    setImgs({ left: "", right: "" });
    setLeftOpacity(0);
    setTimeout(() => {
      setImgs({ left: activeFile.initUrl, right: activeFile.afterUrl });
    });
  }, [activeFile]);

  // console.log("position", position);

  return (
    <Row className="SlideDiff">
      <div className="tip" style={{ left: "15%", zIndex: 10 }}>
        转化前
      </div>

      <div
        className="SlideCol"
        style={{ width: baseImage.width }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={handleMouseUp}
      >
        <div
          className="SlideImg"
          style={{
            width: position + "px",
            height: baseImage.height,
          }}
        >
          <img
            // draggable="false"
            className={classnames("diffImg")}
            src={imgs.left}
            alt=""
            style={{
              width: baseImage.width,
              height: baseImage.height,
              opacity: leftOpacity,
            }}
          />
        </div>

        <div
          ref={draggableRef}
          className="SlideBar"

          // draggable="true"
        >
          <div className="bar" />
          {/* <img src={Image_SilderBar} alt="" className="bar_img" /> */}
          <div className="bar_img" />
        </div>
      </div>

      <div className="tip" style={{ right: "15%" }}>
        转化后
      </div>

      <Button className="tool" type="primary" onClick={handleFullScreen}>
        全屏预览
      </Button>

      <img
        ref={refBaseImg}
        src={imgs.right}
        alt=""
        className="diffImg"
        // draggable="false"
        onLoad={onLoad}
      />
    </Row>
  );
};

export default SlideDiff;

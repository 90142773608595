import React from "react";
import SuperPixel from "./page/SuperPixel";
import "./App.css";

function App () {
  // 设置 rem 函数
  function setRem () {
    // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
    let htmlWidth = document.body.clientWidth
    //得到html的Dom元素
    let htmlDom = document.getElementsByTagName('html')[0]
    //设置根元素字体大小
    htmlWidth / 10 > 102.4
      ? (htmlDom.style.fontSize = '102.4px')
      : (htmlDom.style.fontSize = htmlWidth / 10 + 'px')
  }
  // 初始化
  if (window.judgeEnv.pageEnv != 'pc') {
    setRem()
  }

  return (
    <div className="App">
      <SuperPixel />
    </div>
  );
}

export default App;

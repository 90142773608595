import moment from "moment";
import JSZip from "jszip";
import { FileItem, options } from "../page/SuperPixel/constant";

export function storage (key: string, value?: any) {
  if (value !== undefined) {
    localStorage.setItem(key, JSON.stringify(value));
    return null;
  }
  const output = localStorage.getItem(key) || "null";
  return JSON.parse(output);
}

/**
 * 会话存储
 * @param key
 * @param value
 * @returns
 */
export function session (key: string, value?: any) {
  if (value !== undefined) {
    sessionStorage.setItem(key, JSON.stringify(value));
    return null;
  }
  const output = sessionStorage.getItem(key) || "null";
  return JSON.parse(output);
}

/**
 * 大多数moment格式：2021-07-21 12:21:12
 * @param time
 * @returns
 */
export function customTimeFormat (time: any): string {
  if (time && typeof time === "number") {
    return moment(time).format("YYYY-MM-DD HH:mm:ss");
  }
  return "";
}

/**
 * 将网络获取省市区转换成Picker需要的格式
 * @param data any[];
 * @returns any[];
 *
 */
export function formatRegion (data: any[]): any[] {
  const ret: any[] = [];
  if (!Array.isArray(data) || data.length === 0) {
    console.error("formatRegion 入参错误:", data);
    return ret;
  }
  for (let i = 0; i < data.length; i++) {
    const first = { value: "", label: "", children: [] as any[] };
    first.value = data[i].provinceName;
    first.label = data[i].provinceName;
    for (let j = 0; j < data[i].citys.length; j++) {
      const second = { value: "", label: "", children: [] as any[] };
      // value不能重复，没办法拼上code，选中后截取掉&后边
      second.value =
        data[i].citys[j].cityName + "&" + data[i].citys[j].cityCode;
      second.label = data[i].citys[j].cityName;
      for (let k = 0; k < data[i].citys[j].countys.length; k++) {
        const thrid = { value: "", label: "", children: [] };
        // value不能重复，没办法拼上code，选中后截取掉&后边
        thrid.value =
          data[i].citys[j].countys[k].countyName +
          "&" +
          data[i].citys[j].countys[k].countyCode;
        thrid.label = data[i].citys[j].countys[k].countyName;
        second.children.push(thrid);
      }
      first.children.push(second);
    }
    ret.push(first);
  }
  return ret;
}

/**
 * 为了修正${formatRegion}方法拼接的&code
 */
export function selectedRegionFix (data: any): any {
  const ret: string[] = [];
  if (!data) return ret;
  for (let i = 0; i < data.length; i++) {
    const targetIndex = data[i].indexOf("&");
    if (targetIndex > -1) {
      ret.push(data[i].substring(0, targetIndex));
    } else {
      ret.push(data[i]);
    }
  }
  return ret;
}

export function arraysAreEqual (arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false; // 如果数组长度不相等，则直接返回 false
  }

  // 使用 every() 方法进行比较
  return arr1.every((value, index) => value === arr2[index]);
}

export function formatBytes (size: number): string {
  if (size >= 1024 * 1024 * 1024) {
    return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  } else if (size >= 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  } else if (size >= 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return size.toFixed(2) + " B";
  }
}

export function downloadImage (url: string): void {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const a = document.createElement("a");
        a.href = reader.result as string;
        const fileName = url.split("/").pop() || "image.jpg";
        a.download = fileName;
        a.click();
      };
      reader.readAsDataURL(blob);
    });
}

export async function downloadImages (imageUrls: string[]): Promise<void> {
  // 创建一个新的 JSZip 实例
  const zip = new JSZip();

  // 循环遍历图像 URL 数组
  for (let i = 0; i < imageUrls.length; i++) {
    const imageUrl = imageUrls[i];
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // 将图像添加到压缩包中
    zip.file(imageUrl.split("/").pop() || `image${i + 1}.jpg`, blob);
  }

  // 生成压缩包
  const content = await zip.generateAsync({ type: "blob" });

  // 创建一个用于下载压缩包的链接
  const link = document.createElement("a");
  link.href = URL.createObjectURL(content);
  link.download = "images.zip";
  link.click();

  // 释放资源
  URL.revokeObjectURL(link.href);
}

// 判断图片比例是否需要超分
export function needChange (i: FileItem) {
  let imageNoNeedChange = false;
  const target = options.find((j) => j.value === i.optionValue);
  if (i.imageInfo.width && i.imageInfo.height && target) {
    const image_ratio = Number(i.imageInfo.width) / Number(i.imageInfo.height);
    const page_ratio = Number(target.width) / Number(target.height);
    if (image_ratio / page_ratio < 1) {
      // 图片长宽比/纸张长宽比<1 相反，原图分辨率大于纸张选择后计算出的标准数值，将超分按钮隐藏，修改为红色文字显示（原图过大）
      imageNoNeedChange = true;
    }
    // console.log("image_ratio, page_ratio", image_ratio, page_ratio);
  }
  return imageNoNeedChange;
}



export class JudgeAgent {
  pageEnv: string;
  versions: {};
  constructor() {
    this.pageEnv = ''
    this.versions = {}
    this.judgeEnv()
  }
  getVersion () {
    const u = navigator.userAgent
    return {
      // 移动终端浏览器版本信息
      trident: u.indexOf('Trident') > -1, //IE内核
      presto: u.indexOf('Presto') > -1, //opera内核
      webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
      mobile:
        !!u.match(/AppleWebKit.*Mobile.*/) ||
        u.indexOf('bearmusic') > -1 ||
        u.indexOf('ArtAiClass') > -1 ||
        u.indexOf('artaiclass') > -1, //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1 || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1), //是否iPad
      webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
      isTablet:
        u.indexOf('Macintosh') > -1 ||
        /(?:iPad|PlayBook)/.test(u) ||
        (/(?:Android)/.test(u) && !/(?:Mobile)/.test(u)) ||
        (/(?:Firefox)/.test(u) && /(?:Tablet)/.test(u)),
    }
  }
  judgeEnv () {
    const versions = this.getVersion()
    this.versions = versions
    if (versions.mobile || versions.android || versions.iPad) {
      var ua = navigator.userAgent.toLowerCase() //获取判断用的对象
      if (
        ua.indexOf('newbrushapp') > -1
      ) {
        this.pageEnv = 'app'
      } else if (versions.ios) {
        // 是否在IOS浏览器打开
        this.pageEnv = 'ios'
      } else if (versions.android) {
        // 是否在安卓浏览器打开
        this.pageEnv = 'android'
      }
    } else {
      this.pageEnv = 'pc'
    }
  }
}
interface envInfer {
  pageEnv: string,
  versions: Object,
}
declare global {
  interface Window {
    bear: Function;
    judgeEnv: envInfer;
  }
}
const judgeEnv = new JudgeAgent()
window.judgeEnv = judgeEnv

export function SchemaToApp (url: string, method: Function) {
  window.location.href = url
  window.bear = method
}
import { useCallback } from "react";
// import path from "path";
import { message } from "antd";
import md5 from "md5";
// import { apiGetOssConfig } from '@/services/api';
// import { updalodFile } from '@/utils/upload';
// import { fileNameRules } from "@/utils/file";
import { apiGetOssConfig } from "../api";

/**
 * 使用 axios 上传文件到阿里云oss方法
 */

const fileNameRulesArr = ["+", " ", "/", "?", "%", "#", "&", "="];
export function fileNameRules(name: string) {
  if (/[\u4E00-\u9FA5]/.test(name)) {
    // message.error(`${name} 文件名不能包含中文`);
    name = encodeURIComponent(name);
    // return false;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < fileNameRulesArr.length; i++) {
    if (name.includes(fileNameRulesArr[i])) {
      // message.error(`${name} 文件名不能包含 ${fileNameRulesArr[i]} `);
      // return name;
      name = name.replace(fileNameRulesArr[i], "");
    }
  }
  return name;
}

declare global {
  interface Window {
    OSS: any;
  }
}

export const updalodFile = async (
  paths: string,
  file: any,
  config: Record<string, any>,
  progress?: (p: number) => void
) => {
  const client = new window.OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
    region: "oss-cn-hangzhou",
    endpoint: config.endpoint,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: config.credentials.accessKeyId,
    accessKeySecret: config.credentials.accessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: config.credentials.securityToken,
    // 填写Bucket名称。
    bucket: config.bucketName,
    // cdnUrl,
  });
  const name = `${
    process.env.REACT_APP_ENV === "production" ? "live" : "test"
  }${paths}`;
  // const name = `${
  //   ["prod", "live"].includes(process.env.REACT_APP_ENV) ? "live" : "test"
  // }${path}`;
  // console.log('name', name);
  const data = file;
  const options = {
    // 获取分片上传进度、断点和返回值。
    // progress: (p: any, cpt, res) => {
    progress: (p: number) => {
      // console.log(p);
      if (progress) {
        progress(p);
      }
    },
    // 设置并发上传的分片数量。
    parallel: 4,
    // 设置分片大小。默认值为1 MB，最小值为100 KB。
    partSize: 1024 * 1024,
    // headers,
    // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
    meta: { year: 2020, people: "test" },
    mime: "text/plain",
  };

  const res = await client.multipartUpload(name, data, {
    ...options,
    // 设置上传回调。
    // 如果不涉及回调服务器，请删除callback相关设置。
  });
  // cdn 域名访问
  const imgUrl = `${config.cdnUrl}/${res.name}`;
  return imgUrl;
};

const useUpload = (
  props: { beforePath?: string; useBase64?: boolean } = {}
) => {
  const { beforePath = "", useBase64 } = props;

  const onUpload = useCallback(
    async (file: any, fileName: string, onProgress?: (v: any) => void) => {
      fileName = fileNameRules(fileName);

      if (useBase64) {
        const res: string = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
        });
        return res;
      }

      const [, { data: config }] = (await apiGetOssConfig()) as any;
      // console.log('file', file);
      console.log("config", config);
      const suffix = "." + fileName.split(".").pop();
      const res = await updalodFile(
        `/image/picture/book/enlarge/printFe/${beforePath}${Date.now()}${md5(
          fileName
        )}${suffix}`,
        file,
        config,
        onProgress
      );

      return res;
    },
    [beforePath, useBase64]
  );

  return { onUpload };
};

export default useUpload;
